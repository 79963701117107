body {
  background-color: #1b1b1b;
  color: #fff;
}

.App {
  display: flex;
  text-align: center;
  flex-direction: column;
  scroll-behavior: smooth;
}

@font-face {
  font-family: title;
  src: url(../public/library_3_am.otf);
}

html {
  /* scroll-snap-type: y mandatory; */
  overflow-y: visible;
}

svg {
  margin-top: -1px;
  -webkit-transform: scale(1); 
  transform: scale(1);
}

h1 {
  font-family: title;
  font-size: 3.5em;
  padding: 0;
  margin: 0;
  text-shadow: 1px 1px #000;
}

section.all-wrappers {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  position: sticky;
  /* -webkit-transition: padding .5s ease-in-out; */
}

section.splashscreen {
  height: 100vh;
}

div.bg-image {
  height: 150vh;
  width: 150vw;
  background-image: url('../public/laptop_thin_1080p.jpg');
  background-position: center;
  object-fit: cover;
  background-clip: border-box;
  opacity: 0.5;
}

svg.clip-splash-image {
  position: absolute;
  fill: #6ea6ec;
  background-color: transparent;
  top: 100vh;
}

.align-centre-wrapper {
  align-items: center;
}

.align-top-wrapper {
  align-items: top;
}

.footer {
  color: white;
}

.crowzfx-box {
  background-color: #1b1b1b;
  color: #6ea6ec;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 30px -5px #000;
  position: absolute;
}

.crowzfx-box h1 {
  text-shadow: 2px 2px #000;
}

.crowzfx-box p {
  text-shadow: 1px 1px #000;
  text-transform: uppercase;
}

span#bouncing-indicator {
  position: absolute;
  rotate: 90deg;
  bottom: 30px;
  font-size: 3em;
  color: #ffb929;
  text-shadow: 1px 1px #000;
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes bounce {
  from {
    bottom: 20px;
  }
  to {
    bottom: 40px;
  }
}

span.colour-modifier {
  color: #ffb929;
}

.section-content {
  flex-direction: column;
}

svg.svg-dark {
  fill: #1b1b1b;
  background-color: #6ea6ec;
}

svg.svg-light {
  fill: #6ea6ec;
  background-color: #1b1b1b;
}

svg.svg-alt-rev {
  fill: #1b1b1b; 
  background-color: #ffb929;
  width: 100%;
}

svg.svg-alt {
  fill: #ffb929;
  background-color: #1b1b1b;
  width: 100%;
}

section.about {
  background-color: #6ea6ec;
  padding-bottom: 30px;
  margin-top: 10vh;
}
section.about div.section-content  {
  width: 80%;
}

section.about p {
  padding: 8px 0px;
  text-align: justify;
  text-justify: newspaper;
}

section.contact {
  background-color: #ffb929;
}

section.products {
  margin-top: -20px;
}

section.products p {
  text-transform: uppercase;
}

section.footer {
  min-width: 60vw;
  max-width: 90vw;
  margin: 0 auto;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}

div.footer-top, div.footer-bottom {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

div.footer-bottom-left, div.footer-top {
  text-align: justify;
  text-justify: newspaper;
}

ul.footer-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.footer-ul li {
  padding: 2px 0px;
}

hr {
  width: 100%;
  margin: 15px 0px -5px 0px;
  color: #6ea6ec;
  border: 1.5px solid #6ea6ec;
}

div.instagram-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

div.instagram-wrapper a {
  padding-left: 10px;
  line-height: 25px;
}

a.footer-link:hover {
  color: #ffb929;
}

a.footer-link, a.product-tile-a {
  text-decoration: none;
  color: #fff;
}

svg.instagram-logo {
  height: 30px;
  fill: #ffb929;
}

section.footer h3 {
  color: #6ea6ec;
}

div.product-tile-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 15px;
}

section.about div.section-content p {
  margin: 10px;
}

a.product-tile-a {
  height: 20vh;
  width: 70vw;
  background-color: #ffb929;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1b1b1b;
}

a.product-tile-a:hover {
  background-color: #6ea6ec;
}

a.product-tile-a p {
  text-transform: uppercase;
  width: 90%;
}

a.product-tile-a h3 {
  text-transform: uppercase;
  font-size: 1.6em;
  margin: 10px 0px;
}

a.product-tile-a p.product-p-status {
  margin-top: -10px;
  margin-bottom: 5px;
  color: red;
}

a.product-tile-a p.product-status-red {
  color: red;
}

a.product-tile-a p.product-status-orange {
  color: #fff;
}
a.product-tile-a p.product-status-green {
  color: green;
}

section.contact div.section-content {
  display: flex;
  flex-direction: column;
}

section.contact div.section-content h2 {
  font-size: 1.8rem;
  margin: 25px 0px 5px 0px;
}

h1.policy-header, h1.nomatch-header {
  font-size: 2.5em;
  text-align: center;
}

div.policy-wrapper, div.nomatch-wrapper {
  margin: 25px;
}

div.policy-wrapper p, div.nomatch-wrapper p {
  text-align: justify;
  text-justify: inter-word;
}

div.policy-wrapper span.highlighted-word {
  color: #ffb929;
  font-style: italic;
}

div.policy-wrapper a, div.nomatch-wrapper a {
  text-transform: uppercase;
  color: #fff;
}

div.policy-wrapper a.policy-link, div.nomatch-wrapper a.nomatch-link {
  display: block;
  font-size: 1.2em;
  text-align: center;
}

div.policy-wrapper a:hover, div.nomatch-wrapper a:hover {
  color: #ffb929;
}

div.nomatch-wrapper p {
  text-align: center;
}

@media screen and (min-width: 700px) and (max-width: 899px) {
  section.about {
    margin-top: 20vh;
  }
}

@media screen and (min-width: 900px) and (max-width: 999px) {
  h1 {
    font-size: 5rem;
  }

  div.footer-top, div.footer-bottom {
    flex-direction: row;
  }

  section.about {
    margin-top: 15vh;
  }

  section.products {
    margin-top: -70px;
  }

  a.product-tile-a {
    width: 40vw;
  }

  section.contact div.section-content h2 {
    font-size: 2.4rem;
    /* margin: 15px 0px -15px 0px; */
  }
}

@media screen and (min-width: 1000px) {
  h1 {
    font-size: 6rem;
  }

  div.footer-top, div.footer-bottom {
    flex-direction: row;
  }

  section.products {
    margin-top: -120px;
  }

  section.about {
    margin-top: 20vh;
  }

  a.product-tile-a {
    width: 35vw;
    height: 25vh;
  }

  a.product-tile-a h3 {
    font-size: 2em;
  }

  section.contact div.section-content h2 {
    font-size: 2.8rem;
    margin: 40px 0px 0px 0px;
  }
}

@media screen and (min-width: 1200px) {
  section.about {
    margin-top: 30vh;
    padding-bottom: 40px;
  }

  section.about div.section-content {
    width: 800px;
  }

  a.product-tile-a {
    width: 45vw;
    max-width: 600px;
  }

  section.contact div.section-content h2 {
    margin: 50px 0px 0px 0px;
  }
}

@media screen and (min-width: 1600px) {
  div.bg-image {
    background-image: url('../public/laptop_thin_4178p.jpg');
  }
}